import React, { useState, useEffect } from 'react';
import { Paper, TableBody, TableRow, Box, TableCell, InputAdornment, TextField, Button, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';
import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../common/hooks/useAppContext';
import { RedFlagService } from "../../services/RedFlagService";
import { TextInput } from "../../common/components/common/inputs";
import { useTable, PageHeader, Notification, ConfirmDialog } from '../../common/components/common';
import notifyError from '../../common/errors/notifyError';
import { useBaseStyles } from '../../common/styles';
import { disabledRoles } from '../../common/data';

const headCells = [
    { id: 'ip', label: 'IP' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]

export default function IpRedFlags() {
    const baseClasses = useBaseStyles();
    const [ip, setIp] = useState('');
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        RedFlagService.getIps(axiosPrivate)
            .then(response => {
                isMounted && setRecords(response.data);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);

    async function AddWithCheck(e) {
        e.preventDefault();
        if (!ip) {
            setNotify({
                isOpen: true,
                message: 'Please input ip',
                type: 'error'
            })
            return;
        }
        if (records.find(item => item.ip === ip)) {
            setNotify({
                isOpen: true,
                message: 'IP Existed',
                type: 'error'
            })
        } else {
            try {
                const { data } = await RedFlagService.addIp(axiosPrivate, ip)
                const newIp = { ip, _id: data._id };
                console.log(newIp);
                setRecords([...records, newIp]);

                setNotify({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                })
            } catch (err) {
                notifyError(err, setNotify, navigate, location);
            }
        }
    }


    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    return items.filter(x => x.ip ? x.ip.toLowerCase().includes(target.value.toLowerCase()) : false)
            }
        })
    }

    const handleDelete = async ipId => {
        try {
            await RedFlagService.removeIp(axiosPrivate, ipId);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            const newRecords = records.filter((item) => item._id !== ipId);
            setRecords(newRecords);
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }
    }

    return (
        <>
            <PageHeader
                title="Red Flag"
                subTitle="IP"
            />

            <Paper className={baseClasses.pageContent}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 500
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <TextInput
                                label="Search IPs"
                                className={baseClasses.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                        </Grid>
                        <Grid container item xs={5} className={baseClasses.right}>
                            <form className="form" onSubmit={(e) => AddWithCheck(e)}>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <TextField
                                            required
                                            type='text'
                                            label="IP"
                                            variant="outlined"
                                            size="small"
                                            value={ip}
                                            placeholder="xxx.xxx.xxx.xx"
                                            onChange={(e) => setIp(e.target.value)}
                                            inputProps={{ inputMode: 'numeric', pattern: "([0-9]{1,3}\.){3}[0-9]{1,3}" }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Button sx={{ ml: 2 }} disabled={disabledRoles.includes(auth.user.role)} type='submit' variant="contained">Add</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item._id}>
                                    <TableCell>{item.ip}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            disabled={disabledRoles.includes(auth.user.role)}
                                            startIcon={<DeleteIcon style={{ fontSize: 23, color: 'secondary' }} />}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Are you sure to delete this record?',
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => handleDelete(item._id)
                                                })
                                            }}
                                        >
                                            Delete
                                        </Button>

                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Box>
            </Paper>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}