import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@mui/material';
import { pink } from '@mui/material/colors';

export default function RadioGroup(props) {

    const { name, label, value, onChange, items, disabled } = props;

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <MuiRadioGroup row
                name={name}
                value={value}
                onChange={onChange}>
                {
                    items.map(
                        item => (
                            <FormControlLabel
                                key={item.id}
                                value={item.value}
                                control={<Radio sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                        color: pink[600],
                                    },
                                }} />}
                                disabled={item.disabled || disabled}
                                label={item.label} />
                        )
                    )
                }
            </MuiRadioGroup>
        </FormControl>
    )
}